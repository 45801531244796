import { useState } from "react";
import { SET_COMPETENCY_ANS } from "../api/url";
import api from "../api/api";
import { useDispatch } from "react-redux";
import {
  setFeedBackLoading,
  setFeedBackTotalPages,
} from "../store/FeedBack/action";

const useCompetencyResponse = () => {
  const dispatch = useDispatch();
  const [isCompetencyAnsLoading, setIsLoading] = useState(false);
  const [competencyAnsError, setError] = useState(null);
  const [competencyAnsSuccess, setSuccess] = useState(false);
  const [CompetencyAnsData, setData] = useState(null);

  const setCompetency = async (
    competency_id,
    question_id,
    answer_id,
    partner_id,
    description
  ) => {
    setIsLoading(true);
    dispatch(setFeedBackLoading(true));
    setError(null);
    setSuccess(false);

    try {
      const response = await api.postWithToken(`${SET_COMPETENCY_ANS}`, {
        competency_id,
        question_id,
        answer_id,
        partner_id,
        description,
      });
      if (response?.data?.success) {
        setData({ competency_id, question_id, answer_id, partner_id });
        setSuccess(response?.data?.success);
        dispatch(setFeedBackTotalPages(response?.data?.total_records));
      }
      dispatch(setFeedBackLoading(false));
      setIsLoading(false);
    } catch (error) {
      setData(null);
      setError(error);
      dispatch(setFeedBackLoading(false));
      setIsLoading(false);
    }
  };

  return {
    setCompetency,
    isCompetencyAnsLoading,
    competencyAnsError,
    competencyAnsSuccess,
    CompetencyAnsData,
  };
};

export default useCompetencyResponse;
