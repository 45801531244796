import { useCallback, useEffect, useState } from "react";
import { GET_USERS } from "../api/url";
import api from "../api/api";
import i18next from "i18next";

const useEmployeeData = () => {
  const lang = i18next.language;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const employeeListRequest = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const payload = {
      lang,
    };

    try {
      const response = await api.postWithToken(`${GET_USERS}`, payload);
      if (response?.data?.success) {
        setData(response?.data?.data);
        setFilteredData(response?.data?.data); // Initialize filteredData with all data
        setSuccess(response?.data?.success);
      }
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }, [lang]);

  const resetData = () => {
    setFilteredData(data); // Reset filteredData to all data
  };

  const filterData = (filterCondition) => {
    if (!data) return;

    const filtered = data.records.filter(
      (record) => record.feedback_state === filterCondition
    );
    setFilteredData({ ...data, records: filtered });
  };

  useEffect(() => {
    employeeListRequest();
  }, [employeeListRequest]);

  return {
    isLoading,
    error,
    success,
    data,
    filteredData,
    filterData,
    resetData,
  };
};

export default useEmployeeData;
