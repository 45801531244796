import { useCallback, useEffect, useState } from "react";
import { URL, GET_USER_INFO } from "../api/url";
import i18next from "i18next";
import { STORAGEKEY } from "../api";
import api from "../api/api";

const useUserData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);
  const PARTNER_ID = localStorage.getItem(STORAGEKEY.PARTNER_ID);

  const userData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const payload = {
      lang: i18next.language,
      partner_id: PARTNER_ID,
    };

    try {
      const response = await api.postWithToken(`${GET_USER_INFO}`, payload);
      if (response?.data?.success) {
        setData(response?.data?.data);
        setSuccess(response?.data?.success);
      }
    } catch (error) {
      setData(null);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [PARTNER_ID]);

  useEffect(() => {
    userData();
  }, [userData]);

  return { isLoading, error, success, data };
};

export default useUserData;
