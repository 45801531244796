import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import NoDataFound from "../../assets/NoDataFound.png";
import { useTranslation } from "react-i18next";

import useEmployeeData from "../../hooks/useEmployeeData";
import { useState } from "react";

// create a utility for taking feedback state as new, in_progress and completed and yielding a t() value
const getFeedbackState = (feedbackState, t) => {
  switch (feedbackState) {
    case "new":
      return t("NotStarted");
    case "in_progress":
      return t("InProgress");
    case "done":
      return t("Completed");
    default:
      return t("NotStarted");
  }
};

const EmployeeReview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedFilter, setSelectedFilter] = useState("Show All");

  const {
    isLoading: AllUsersLoading,
    filteredData: FilteredUsers,
    filterData,
    resetData,
  } = useEmployeeData();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DBECE4",
      color: "#3D7C65",
      fontWeight: "600",
      borderColor: "#F6FBF9",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: "#F7FCF4",
      borderColor: "#3D7C6580",
    },
    "&:nth-of-type(1)": {
      width: "25%", // Set the width of the first column
      minWidth: "250px",
    },
    "&:nth-of-type(2)": {
      width: "30%", // Set the width of the first column
      minWidth: "300px",
    },
    "&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6)": {
      width: "15%", // Set the width of the other columns
      minWidth: "180px",
    },
    "&:last-child": {
      minWidth: "120px",
      position: "sticky",
      right: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F6FBF9",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F6FBF9",
    },

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const Option = ({ color, text }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: "12px",
            height: "12px",
            backgroundColor: color,
            borderRadius: "50%",
          }}
        ></Box>
        <Typography
          sx={{
            marginLeft: "12px",
            fontSize: "16px",
            fontWeight: "600",
            color: "#15134B",
          }}
        >
          {t(text)}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 101px)",
        width: "100%",
        backgroundColor: "#F7FCF4",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "12px 0",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "31px",
              letterSpacing: "0em",
              textAlign: "start",
              color: "#15134B",
              padding: "5px 0 ",
            }}
          >
            {t("EmployeesList")}
          </Typography>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            sx={{
              "& .MuiInputBase-input": {
                padding: "10px 15px",
              },
              input: {
                borderRadius: "8px",
                border: "2px solid #E2E2EA",
              },
              margin: "0px 0px 0px 30px",
              width: "250px",
            }}
            value={selectedFilter}
            onChange={(e) => {
              setSelectedFilter(e.target.value);
              if (e.target.value === "Show All") {
                resetData();
              } else {
                filterData(e.target.value);
              }
            }}
          >
            <MenuItem value={"Show All"}>
              <Option color={"#8C8C8C"} text={t("ShowAll")} />
            </MenuItem>
            <MenuItem value={"new"}>
              <Option color={"#FFA3A3"} text={t("FeedbackNotStarted")} />
            </MenuItem>
            <MenuItem value={"in_progress"}>
              <Option color={"#FFCC0F"} text={t("FeedbackInProgress")} />
            </MenuItem>
            <MenuItem value={"done"}>
              <Option color={"#3D7C65"} text={t("FeedbackCompleted")} />
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "15px 0",
          height: "calc(100vh - 225px)",
          overflow: "auto",
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead sx={{ borderRadius: "8px" }}>
              <TableRow sx={{}}>
                <StyledTableCell sx={{ borderRadius: "8px 0 0 8px" }}>
                  {t("EMPLOYEE_NAME")} ({FilteredUsers?.records?.length || 0})
                </StyledTableCell>
                <StyledTableCell>{t("EMPLOYEE_JOB_ID")}</StyledTableCell>
                <StyledTableCell>{t("EMPLOYEE_EMAIL")}</StyledTableCell>
                <StyledTableCell>{t("FEEDBACK_STATUS")}</StyledTableCell>
                <StyledTableCell>{t("TIMESTAMP")}</StyledTableCell>
                <StyledTableCell
                  sx={{ borderRadius: "0 8px  8px 0" }}
                  // align="right"
                >
                  {t("ACTIONS")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "#F7FCF4" }}>
              {AllUsersLoading || FilteredUsers?.records?.length === 0 ? (
                <StyledTableRow key={1} sx={{ width: "100%" }}>
                  <StyledTableCell colSpan={4}>
                    <Box
                      sx={{
                        backgroundColor: "#F7FCF4",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "30px",
                      }}
                    >
                      <img src={NoDataFound} height={200} alt="Not found" />
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "20px",
                          fontWeight: "600",
                          lineHeight: "31px",
                          letterSpacing: "0em",
                          textAlign: "start",
                          color: "#15134B",
                          padding: "20px 0 ",
                        }}
                      >
                        {t("NoUsersFound")}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                FilteredUsers?.records?.map((row, i) => (
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell component="th" scope="row">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#3D7C65",
                          fontWeight: "600",
                        }}
                      >
                        {row?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#3D7C65",
                          fontWeight: "600",
                        }}
                      >
                        {row?.job_id[1]}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#3D7C65",
                          fontWeight: "600",
                        }}
                      >
                        {row?.email}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Option
                        color={
                          row?.feedback_state === "done"
                            ? "#3D7C65"
                            : row?.feedback_state === "in_progress"
                            ? "#FFCC0F"
                            : "#FFA3A3"
                        }
                        text={getFeedbackState(row?.feedback_state, t)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography
                        sx={{
                          marginLeft: "12px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#15134B",
                        }}
                      >
                        {row?.feedback_submitted_on || "-"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        key={i}
                        disabled={row?.feedback_state === "done"}
                        sx={{
                          bgcolor: "#3D7C65",
                          padding: "6px 8px",
                          fontSize: "14px",
                          fontWeight: "500",
                          borderRadius: "4px",
                          textTransform: "none",
                          width: "120px",
                          "&:hover": {
                            bgcolor: "#2faa7d !important",
                          },
                        }}
                        variant="contained"
                        onClick={() => {
                          navigate("/instructions", { state: row });
                        }}
                      >
                        {t("GiveFeedback")}
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default EmployeeReview;
