import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import LoginPage from "../pages/Login";
import DashboardLayout from "../components/layouts/DashboardLayout";
import Feedback360Degree from "../pages/Feedback360Degree";

import { isAuthenticated } from "./isAuthenticated";
import WithHeaderLayout from "../components/layouts/WithHeaderLayout";
import CompetencyInstructions from "../components/instructions/CompetencyInstructions";
import DashboardFormLayout from "../components/layouts/DashboardFormLayout";
import EmployeeFeedbackLayout from "../components/layouts/EmployeeFeebackLayout";
import EmployeeFeedbackPage from "../pages/EmployeeFeedback";
import SJQ from "../pages/SJQ";
import UbaLayout from "../components/layouts/UbaLayout";
import UBAPage from "../pages/UBA";

const PrivateRoute = ({ children }) => {
  const access_token = isAuthenticated();
  return access_token ? children : <Navigate to="/login" replace />;
};

const PublicRoute = ({ children }) => {
  const access_token = isAuthenticated();
  return !access_token ? children : <Navigate to="/" replace />;
};

const Layout = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />} />
      <Route path="/dashboard" element={<DashboardLayout />} />

      <Route
        path="/instructions"
        element={<WithHeaderLayout children={<CompetencyInstructions />} />}
      />
      <Route
        path="/feedback_questions"
        element={<EmployeeFeedbackLayout children={<EmployeeFeedbackPage />} />}
      />
      <Route
        path="/feedback_360degree"
        element={<DashboardFormLayout children={<Feedback360Degree />} />}
      />
      {/* <Route path="/sjq" element={<DashboardFormLayout children={<SJQ />} />} />
      <Route
        path="/uba-1-4"
        element={<UbaLayout children={<UBAPage type={"uba-1-4"} />} />}
      />
      <Route
        path="/uba-5-14"
        element={<UbaLayout children={<UBAPage type={"uba-5-14"} />} />}
      /> */}
    </Routes>
  );
};

const RootRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RootRouter;
