import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";

const ProficiencyLevelDropdown = ({
  t,
  title,
  hintText,
  proficiencyCount,
  selectedValue,
  handleChange,
  disabled,
  competencyId,
  questionId,
  handlePsuedoChange,
}) => {
  // Check if `selectedValue` is provided and is not zero. If it's not provided or zero, default to 1
  const normalizedSelectedValue = selectedValue > 0 ? selectedValue : 0;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
        padding: "0.5rem 1rem",
        borderRadius: "8px",
        backgroundColor: "#FFFFFFB2",
      }}
    >
      {hintText && (
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          {hintText}
        </Typography>
      )}

      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Typography
          sx={{
            borderRadius: "4px",
            padding: "8px 0",
            color: "#3D7C65",
            fontWeight: "bold",
          }}
        >
          {title}:
        </Typography>

        <FormControl>
          <Select
            value={normalizedSelectedValue}
            onChange={
              !!handleChange
                ? handleChange
                : (e) =>
                    handlePsuedoChange(competencyId, questionId, e.target.value)
            }
            disabled={disabled}
            sx={{
              width: {
                lg: "200px",
              },
              height: "45px",
              "&.MuiOutlinedInput-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                {
                  border: "1px solid #0f0f0f4dl",
                },
              "&.MuiOutlinedInput-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "black",
                },
              "& .MuiSelect-iconOutlined": {
                color: "black",
              },
            }}
          >
            <MenuItem key={0} value={0} disabled>
              {title}
            </MenuItem>
            {Array.from({ length: proficiencyCount }, (_, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                {t("PL")} {index + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ProficiencyLevelDropdown;
