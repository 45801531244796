export const ProdURL = `https://api.kpmgvcb.com/api/v1/`;
export const DevUrl = `https://devapi.kpmgvcb.com/api/v1/`;

// ? Authentication URL
export const LOGIN_URL = "login/";
export const GET_USER_INFO = "get_user_info/";
export const GET_USERS = "get_users/";
export const GET_COMPETENCY = "get_competency/";
export const SET_COMPETENCY_ANS = "submit_competency_answer/";
export const SET_ASSESSMENT_ANS = "submit_competency_answer_pl_wise/";
export const SET_PROFICIENCY_ANS = "submit_competency_proficiency_answer/";
export const SET_ASSESSMENT_TIMER = "feedback_time_spent_employee_wise/";
export const SET_SUBMIT_FEEDBACK_FORM = "submit_feedback_status_employee_wise/";
export const GET_SJQ_QUESTIONS = "get_sjq_competency/";
export const GET_UBA_COMPETENCY = "get_uba_competency/";

export const URL = ProdURL;
