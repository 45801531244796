import { Box, ListItemText, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Category, People } from "iconsax-react";
import { useTranslation } from "react-i18next";
import { setActiveKey } from "../store/Product/action";
import useUserData from "../hooks/useUserData";

const DashboardSidebar = ({ employListShow, competencyTestShow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeKey = useSelector((state) => state.productReducer.active_id);
  const { data } = useUserData();

  return (
    <Box
      sx={{
        backgroundColor: "#3D7C65",
        height: "100vh",
        minHeight: "700px",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: "0px solid #FFFFFFCC",
          borderWidth: "0px 0 0.1px 0",
          borderColor: "#FFFFFFCC",
          padding: "24px 6px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "22px",
              fontWeight: "700",
              margin: "10px 0",
            }}
          >
            {t("HELLO")}, {data?.name || "Manager"} 👋
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#FFFFFFCC",
              fontSize: "16px",
              fontWeight: "200",
              margin: "10px 0",
            }}
          >
            {t("WelcomeToVCB")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{}}>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "16px",
            fontWeight: "500",
            margin: "20px 0",
          }}
        ></Typography>
      </Box>
      <Box>
        {employListShow && (
          <MenuItem
            sx={{
              backgroundColor: activeKey === 1 ? "#fff" : "#3D7C65",
              borderRadius: "8px",
              marginBottom: "10px",
              ":hover": {
                backgroundColor: activeKey === 1 ? "#ffffffc8" : "#ffffff7a",
              },
            }}
            key={0}
            onClick={() => {
              dispatch(setActiveKey(1));
            }}
          >
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      objectFit: "fill",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <People
                      size="24"
                      color={activeKey === 1 ? "#3D7C65" : "#fff"}
                    />
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginLeft: "1em",
                      color: activeKey === 1 ? "#3D7C65" : "#fff",
                      fontWeight: "600",
                    }}
                  >
                    {t("EmployeeReview")}
                  </Typography>
                </Box>
              }
              sx={{ maxWidth: "90%" }}
            />
          </MenuItem>
        )}
        {competencyTestShow && (
          <MenuItem
            sx={{
              backgroundColor: activeKey === 2 ? "#fff" : "#3D7C65",
              borderRadius: "8px",
              marginBottom: "10px",
              ":hover": {
                backgroundColor: activeKey === 2 ? "#ffffffc8" : "#ffffff7a",
              },
            }}
            key={1}
            onClick={() => {
              dispatch(setActiveKey(2));
            }}
          >
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      objectFit: "fill",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Category
                      size="24"
                      color={activeKey === 2 ? "#3D7C65" : "#fff"}
                    />
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginLeft: "1em",
                      color: activeKey === 2 ? "#3D7C65" : "#fff",
                      fontWeight: "600",
                    }}
                  >
                    {t("Assignment")}
                  </Typography>
                </Box>
              }
              sx={{ maxWidth: "90%" }}
            />
          </MenuItem>
        )}
        {/* <MenuItem
          sx={{
            backgroundColor: activeKey === 3 ? "#fff" : "#3D7C65",
            borderRadius: "8px",
            marginBottom: "10px",
            ":hover": {
              backgroundColor: activeKey === 3 ? "#ffffffc8" : "#ffffff7a",
            },
          }}
          key={2}
          onClick={() => {
            dispatch(setActiveKey(3));
          }}
        >
          <ListItemText
            primary={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    objectFit: "fill",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Category
                    size="24"
                    color={activeKey === 3 ? "#3D7C65" : "#fff"}
                  />
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginLeft: "1em",
                    color: activeKey === 3 ? "#3D7C65" : "#fff",
                    fontWeight: "600",
                  }}
                >
                  {t("SJQ")}
                </Typography>
              </Box>
            }
            sx={{ maxWidth: "90%" }}
          />
        </MenuItem>
        <MenuItem
          sx={{
            backgroundColor: activeKey === 4 ? "#fff" : "#3D7C65",
            borderRadius: "8px",
            marginBottom: "10px",
            ":hover": {
              backgroundColor: activeKey === 4 ? "#ffffffc8" : "#ffffff7a",
            },
          }}
          key={3}
          onClick={() => {
            dispatch(setActiveKey(4));
          }}
        >
          <ListItemText
            primary={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    objectFit: "fill",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Category
                    size="24"
                    color={activeKey === 4 ? "#3D7C65" : "#fff"}
                  />
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginLeft: "1em",
                    color: activeKey === 4 ? "#3D7C65" : "#fff",
                    fontWeight: "600",
                  }}
                >
                  {t("UBA 1-4")}
                </Typography>
              </Box>
            }
            sx={{ maxWidth: "90%" }}
          />
        </MenuItem>
        <MenuItem
          sx={{
            backgroundColor: activeKey === 5 ? "#fff" : "#3D7C65",
            borderRadius: "8px",
            marginBottom: "10px",
            ":hover": {
              backgroundColor: activeKey === 5 ? "#ffffffc8" : "#ffffff7a",
            },
          }}
          key={4}
          onClick={() => {
            dispatch(setActiveKey(5));
          }}
        >
          <ListItemText
            primary={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    objectFit: "fill",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Category
                    size="24"
                    color={activeKey === 5 ? "#3D7C65" : "#fff"}
                  />
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginLeft: "1em",
                    color: activeKey === 5 ? "#3D7C65" : "#fff",
                    fontWeight: "600",
                  }}
                >
                  {t("UBA 5-14")}
                </Typography>
              </Box>
            }
            sx={{ maxWidth: "90%" }}
          />
        </MenuItem> */}
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
