import { Box, ButtonBase, Typography } from "@mui/material";
import AssignmentPhoto from "../../assets/Assignment.png";
import InstructionsTitle from "../../assets/InstructionsTitle.png";
import InstructionsBack from "../../assets/InstructionsBack.png";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useUserData from "../../hooks/useUserData";

import TypographyPoint from "../header/TypographyPoint";
import useFormStatus from "../../hooks/useFormStatus";

const CompetencyInstructions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { data } = useUserData();

  const { formStatusRequest } = useFormStatus();

  return (
    <Box
      sx={{
        backgroundImage: `url(${InstructionsBack})`,
        height: "88svh",
        width: "100%",
        padding: "30px",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          border: "1px solid #3D7C65",
          borderRadius: "8px",
          margin: "0px 10%",
          backgroundColor: "#F7FCF4",
        }}
      >
        <Box sx={{ padding: "0", height: "10svh", position: "relative" }}>
          <img
            src={InstructionsTitle}
            width={"100%"}
            height={"100%"}
            style={{
              borderRadius: "6px 6px 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            alt="Instructions Title"
          />
          <Box
            sx={{
              padding: "0.5rem 1rem",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#fff",
                fontSize: "28px",
                fontWeight: "600",
                textAlign: "center",
                zIndex: 999,
              }}
            >
              {t(data ? data?.name : "Manager")}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#FFFFFFB2",
                fontSize: "16px",
                textAlign: "center",
                zIndex: 999,
              }}
            >
              {t("EmployeeAssignmentForm")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            height: "70svh",
            padding: "0 2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0",
            }}
          >
            <img src={AssignmentPhoto} height={200} alt="Assignment" />
          </Box>
          <Box>
            <Typography variant="h6">{t("GeneralHeader")}</Typography>
            <TypographyPoint text={t("GeneralPoint1FF")} />
            <TypographyPoint text={t("GeneralPoint3FF")} />
            <Typography
              sx={{
                color: "#15134B",
                fontSize: "16px",
                fontWeight: "400",
                marginTop: "7px",
                marginLeft: "50px",
              }}
            >
              {t("GeneralPoint3FFP1")}
            </Typography>
            <Typography
              sx={{
                color: "#15134B",
                fontSize: "16px",
                fontWeight: "400",
                marginTop: "7px",
                marginLeft: "50px",
              }}
            >
              {t("GeneralPoint3FFP2")}
            </Typography>
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            <Typography variant="h6">{t("InstructionHeader")}</Typography>
            <TypographyPoint text={t("InstructionPoint1FF")} />
            <TypographyPoint text={t("InstructionPoint2FF")} />
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ButtonBase
              sx={{
                border: "1px solid #3D7C65",
                borderRadius: "8px",
                margin: "25px 0",
                padding: "8px",
                width: "280px",
              }}
              variant="outlined"
              onClick={() => {
                formStatusRequest({ feedbackStatus: "in_progress" });
                navigate("/feedback_questions", { state: location.state });
              }}
            >
              <Typography sx={{ color: "#3D7C65" }}>
                {t("StartAssignment")}
              </Typography>
            </ButtonBase>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompetencyInstructions;
