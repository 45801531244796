import { useCallback, useState } from "react";
import { SET_SUBMIT_FEEDBACK_FORM } from "../api/url";
import api from "../api/api";
import { useLocation } from "react-router-dom";

const useFormStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const { state } = useLocation();

  const PARTNER_ID = state?.id || localStorage.getItem("PARTNER_ID");

  const formStatusRequest = useCallback(
    async ({ feedbackStatus }) => {
      if (!PARTNER_ID) return;

      setIsLoading(true);
      setError(null);
      setSuccess(false);

      const payload = {
        partner_id: PARTNER_ID,
        state: feedbackStatus,
      };

      try {
        const response = await api.postWithToken(
          `${SET_SUBMIT_FEEDBACK_FORM}`,
          payload
        );
        if (response?.data?.success) {
          setSuccess(response?.data?.success);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [PARTNER_ID]
  );

  return {
    formStatusRequest,
    isLoading,
    error,
    success,
  };
};

export default useFormStatus;
